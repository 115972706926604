@import "../../styles/base";

.footer {
    background: $blue-footer;
    clear: both;
    margin-top: 2rem;
    padding-top: 2rem;
    padding-bottom: 2rem;
    text-align: center;

    @include fromTablet {
        padding-top: 3rem;
        padding-bottom: 3rem;
    }
}

.container {
    @include container;

    color: $white;

    @include fromTablet {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    p {
        margin: 0;
    }
}