@import "../../styles/base";

.header {
  position: sticky;
  top: 0;
  z-index: 4;
  background-color: white;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.container {
  @include container;

  @include untilTablet {
    padding: 0;
  }
}

.inpageNav {

  ul {
    overflow-y: auto;
    // hide scrollbar Edge, firefox
    -ms-overflow-style: none;
    /* stylelint-disable */
    scrollbar-width: none;
    /* stylelint-enable */
  
    position: sticky;
    margin: 0;
    padding: 0;
    z-index: 3;
    display: flex;
    align-items: stretch;
    clear: left;
    background: #fff;
  }

  li {
    display: inline-block;
  }

  a {
    display: block;
    color: $blue-dark;
    font-weight: 400;
    border: none;
    padding: 16px;
    padding-bottom: 12px;
    margin: 0;
    height: 100%;
    text-decoration: none;
    font-size: 1.8rem;

    @include untilDesktop {
      width: 100%;
      font-size: 1.6rem;
      white-space: nowrap;
    }

    &:hover {
      color: $blue;
    }

  }
}

