@import "vendors/normalize";
@import "base";
html {
    scroll-behavior: smooth;
    font-size: 62.5%;
    box-sizing: border-box;

    * {
        box-sizing: border-box;
    }
}

body {
    font-size: 1.4rem;
    line-height: 1.5;
    color: $text-color;
}

a:link,
a:visited {
    color: $blue;
    text-decoration: none;
}

.flex7030 {
    @include fromTablet {
        display: flex;
        gap: 20px;
    }
}

.cartContainer {
    @include untilTablet {
        position: sticky;
        top: 128px;
        z-index: 1;
    }

    @include fromTablet {
        order: 1;
        flex: 0 0 25%;
    }
}

.mainContainer {
    @include fromTablet {
        // allow for 20px gap in flex layout
        flex: 0 0 calc(75% - 20px);
    }
}

.popup-overlay {
    background: rgba(0, 26, 38, 0.25);
    backdrop-filter: blur(7.5px);
}


// react-calendar overide standard styling
body .react-calendar {
    margin-bottom: 1.4em;
    padding: 10px;
    border: 1px solid $grey;
    border-radius: 8px;
    width: 450px;

    @include untilTablet {
        width: 100%;
        padding: 5px;
    }

    .react-calendar__month-view__weekdays {
        text-transform: none;
    }

    .react-calendar__tile {
        box-shadow: 0 0 1px $grey inset;
    }

    .react-calendar__tile--active {
        color: $white;

        .dayPrice {
            color: $white;
            font-size: 1.1rem;
        }
    }

    .react-calendar__navigation {
        align-items: center;
    }

    .react-calendar__navigation__label:disabled {
        background: $white;
        color: $text-color;
        font-size: 1.6rem;
    }

    .react-calendar__navigation__next2-button,
    .react-calendar__navigation__prev2-button {
        display: none;
    }

    .react-calendar__navigation__prev-button:disabled,
    .react-calendar__navigation__next-button:disabled {
        visibility: hidden;
    }

    .react-calendar__navigation__prev-button,
    .react-calendar__navigation__next-button {
        color: $blue;
        font-weight: bold;
        font-size: 2rem;
        background: #F3F8FE;
        border-radius: 50%;
        width: 30px;
        height: 30px;
        min-width: 0;
        margin-left: 20px;
    }

    .react-calendar__navigation__next-button {
        margin: 0 20px 0 0;
    }

    .react-calendar__month-view__days__day--weekend {
        color: $black;
    }
}

.dayPrice {
    color: $blue;
    font-size: 1.1rem;
}

// modals 
.modal {
    @include boxShadow;
    max-width: $max-width;
    max-height: 100vh;
    height: 100vh;
    padding: 1rem;
    overflow-y: auto;
    background: $white;
  
    @include fromTablet {        
      max-height: calc(100vh - 4rem);
      height: auto;
      border-radius: 12px;
      border: 1px solid $grey;
      padding: 4rem;
    }
  
    h2 {
      font-size: 2rem;
      margin-top: 0;
      padding-right: 40px;
      line-height: 1.3;
  
      @include fromTablet {
        font-size: 3rem;
      }
    }
  }
  
  .modalLink {
    color: $blue-light;
    border: none;
    background: none;
    padding: 0;
    text-decoration: underline;
    appearance: none;
  
    &:hover,
    &:focus {
      cursor: pointer;
      color: darken($blue-light, 10%);
    }
  }
  
  .visuallyHidden {
    @include visuallyHidden;
  }
  
  .close {
    position: absolute;
    right: 0;
    top: 0;
    border: none;
    height: 32px;
    width: 32px;
    background: $white;
    backdrop-filter: blur(5px);
  
    @include fromTablet {
      top: 20px;
      right: 20px;
    }
    
    cursor: pointer;
    z-index: 1;
  
    &::before,
    &::after {
      content: '';
      display: block;
      height: 4px;
      width: 26px;
      background: $blue;
      border-radius: 4px;
      position: absolute;
      top: calc(50% - 2px);
      left: 1px;
      transform: rotate(45deg);
    }
    &::after {
      transform: rotate(-45deg);
    }
  }

  .loadingDots {
    display: inline-block;
    position: absolute;
    
    &::after {
        content: '';
        animation-duration: 1s;
        animation-name: dots;
        animation-iteration-count: infinite;
    }
  }

  @keyframes dots {
    from {
    }

    30% {
        content: '.';
    }
  
    75% {
        content: '..';
    }
  
    to {
        content: '...';
    }
  }