
$tablet: 768px;
$desktop: 1024px;



// colors
$grey: #B3B4B6;
$grey-light: #F4F4F4;
$grey-dark: #BBBBBD;
$grey-text: #505050;
$green: #63AD31;
$green-dark: #128D15;
$green-light: #F6FAF3;
$blue: #0076C6;
$blue-light: #009FE3;
$blue-dark: #003C59;
$blue-darker: #070749;
$blue-cart: #B0E4E2;
$blue-footer: #13203A;
$white: #fff;
$black: #000;
$orange: #F9D069;
$orange-dark: #E69038;
$text-color: #050D11;
$cta-bg: linear-gradient(180deg, #F6CC45 0%, #FCC12C 100%);
$cta-bg-hover: linear-gradient(180deg, #fbc619 0%, #e9b226 100%);

// sizes
$max-width: 1200px;
$font-xs: 1rem;
$font-s: 1.1rem;
$font-default: 1.4rem;
$font-m: 1.6rem;
$font-l: 2rem;
$font-xl: 2.4rem;
$font-xxl: 3.6rem;

$border-radius: 6px;
$border-radius-s: 2px;
$border-radius-m: 4px;

@mixin untilTablet {
  @media only screen and (max-width: calc($tablet - 1px)) {
    @content;
  }
}

@mixin fromTablet {
  @media only screen and (min-width: $tablet) {
    @content;
  }
}

@mixin untilDesktop {
  @media only screen and (max-width: calc($desktop - 1px)) {
    @content;
  }
}

@mixin fromDesktop {
  @media only screen and (min-width: $desktop) {
    @content;
  }
}

@mixin container {
  padding: 0 1rem;
  margin: 0 auto;
  clear: both;
  position: relative;
  max-width: $max-width;
}

@mixin visuallyHidden {
  position: absolute !important;
  overflow: hidden;
  width: 1px;
  height: 1px;
  padding: 0;
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
}

.visuallyHidden {
  @include visuallyHidden;
}

@mixin boxShadow() {
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.25);
}

@mixin hideOnMobile {
  @include untilTablet {
    @include visuallyHidden;
  }
}

@mixin gradient {
  background-image: linear-gradient(rgb(0, 94, 199) 0%, rgb(0, 94, 199) 25%, rgb(5, 116, 240));
}

@mixin loading() {
  position: relative;
  pointer-events: none;

  // overlay
  &::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: $white;
    z-index: 3;
    opacity: 0.7;
  }

  // spinner
  &::after {
    background: none;
    content: "";
    box-sizing: border-box;
    position: absolute ;
    top: 50% !important;
    left: 50% !important;
    width: 20px !important;
    height: 20px !important;
    margin-top: -10px !important;
    margin-left: -10px !important;
    border-radius: 50% !important;
    display: inline-block !important;
    border: 4px solid $green;
    border-top-color: transparent;
    z-index: 4;
    animation: spinner 0.6s linear infinite;
  }
}

@mixin btn {
  font-size: 1.8rem;
  font-weight: 500;
  padding: 1.2rem 2rem;
  color: $text-color;
  background: $cta-bg;
  line-height: 1.2;
  text-align: center;
  text-decoration: none;
  border: none;
  border-radius: $border-radius-m;
  cursor: pointer;
  transition: all 0.3s;
  position: relative;
  width: 100%;

  &:link,
  &:visited {
    color: $text-color;
  }

  &:hover,
  &:focus {
    background: $cta-bg-hover;
  }

  &:disabled {
    background: $grey;
    cursor: not-allowed;

    &:hover,
    &:focus {
      background: $grey;
    }
  }

  &.selected {
    background: $green;
    color: $white;

    &:hover,
    &:focus {
      background: $green-dark;
    }
  }
}