@import "../../../styles/base";

.topBar {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    width: 100%;
}

.left {
    padding: 10px;
}

.right {
    text-align: right;
    padding: 10px;

    @include fromTablet {
        display: flex;
        gap: 10px;
    }
}