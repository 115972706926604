@import '../../../../styles/base';

.login {
    position: relative;
    margin-left: 30px;

    a {
        &:link,
        &:visited {
            color: $blue-dark;
        }
    }
}


.toggle {
    background: url(../../../../images/bg/user.svg) no-repeat left center;
    padding: 0 0 0 20px;
}

.loginMenu {
    position: absolute;
    right: 0;
    background: $white;
    padding: 8px 15px;
    border: 1px solid $grey;
    list-style: none;
    margin: 10px 0 0;
    z-index: 4;
}