@import "../../../../styles/base";

.number {

    @include untilTablet {
        padding: 10px 0;
        margin: -10px 0 0;
        display: block;
        font-size: 1.6rem;
        line-height: 1;
    }

    &:link,
    &:visited {
        color: $black;
        font-weight: bold;
    }
}

.call {
    cursor: help;
    display: inline-block;
    padding-right: 10px;

    @include untilTablet {
        display: none;
    }

    span {
        display: inline-block;
        position: relative;
        padding: 0 20px 0 0;
        color: #FCC12C;
        background: url(../../../../images/bg/info.svg) no-repeat right 3px;
        background-size: 14px auto;
        
    }

    &:hover {
        .popup {
            width: auto;
            height: auto;
            clip: initial;
            right: 0;
            background: $white;
            padding: 8px 15px;
            border: 1px solid $grey;
        }
    }
}

.popup {
    position: absolute !important;
    overflow: hidden;
    width: 1px;
    height: 1px;
    padding: 0;
    border: 0;
    clip: rect(1px, 1px, 1px, 1px);
}